.dropdown {
    position: relative;
    display: inline-block;
}

/* Dropdown Content (Hidden by Default) */



/* Links inside the dropdown */
.dropdown-content a {
    color: black;
    padding: 12px 16px;
    font-size: 16px;
    text-decoration: none;

    display: block;
}

/* Change color of dropdown links on hover */
.dropdown-content a:hover {
    background-color: #ddd;
    color: black;
}

/* Show the dropdown menu on hover */
.dropdown:hover .dropdown-content {
    display: block;
    color: #03d958;
}

/* Change the background color of the dropdown button when the dropdown content is shown */

.dropdown {
    position: relative;
    display: inline-block;
}

/* Dropdown Content (Hidden by Default) */
.dropdown-content {
    display: none;
    position: absolute;
    left: 0;
    border: 1px solid white;
    padding: 10px;

    background-color: #1d1c1c;
    width: fit-content;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
}



/* Links inside the dropdown */
.dropdown-content a {
    color: black;
    padding: 12px 16px;
    font-size: 16px;
    text-decoration: none;

    display: block;
}

/* Change color of dropdown links on hover */
.dropdown-content a:hover {
    background-color: #ddd;

}

/* Show the dropdown menu on hover */
.dropdown:hover .dropdown-content {
    display: block;
    color: #03d958;
}
