@tailwind base;
@tailwind components;
@tailwind utilities;

/* .rc-md-editor .rc-md-navigation .button-wrap  .button   {
    color: blue !important;
    background-color: blue !important;
} */

body {
    color: white;
    background-color: black;
}
@font-face {
    font-family: "AkiraExpanded";
    src: url("../src//Assets/fonts/AkiraESB/Akira\ Expanded\ Super\ Bold.ttf")
        format("truetype");
}

@font-face {
    font-family: "EurostileExtended";
    src: url("../src//Assets//fonts//eurostile//eurostile.TTF")
        format("truetype");
}
@font-face {
    font-family: "EurostileExtendedBlack";
    src: url("../src//Assets//fonts//eurostile/EurostileExtendedBlack.ttf")
        format("truetype");
}

@font-face {
    font-family: "SFPRODISPLAYREGULAR";
    src: url("../src//Assets//fonts/sfprodisplay/SFPRODISPLAYREGULAR.OTF")
        format("opentype");
}
@font-face {
    font-family: "SFPRODISPLAYBOLD";
    src: url("../src//Assets//fonts/sfprodisplay/SFPRODISPLAYBOLD.OTF")
        format("opentype");
}

.homespan span {
       font-family: "AkiraExpanded", sans-serif;
    font-size: 22px;
 
    color: #3FFFFF;
   	line-height: 1.25;

}

.subscribeButton {
    background-color: #3fffff;
    color: black;
    font-size: 600;
    gap: 6px;
    align-items: center;
    line-height: 19px;
    font-family: "SFPRODISPLAYBOLD";
    padding: 8px 20px;
    display: flex;
}

h1 {
    font-weight: 700;
    font-size: 32px;
    line-height: 35.84px;
    color: white;
    font-family: "AkiraExpanded", sans-serif;
    letter-spacing: -2.5%;
}

h2 {
    font-weight: 700;
    font-size: 24px;
    line-height: 30.32px;
    color: white;
        font-family: "AkiraExpanded", sans-serif;
    letter-spacing: -4.5%;
    size: 24px;

 
}
h3 {
    font-weight: 700;
    font-size: 20px;
    line-height: 23.07px;
    color: #ffffffc9;
}
h4 {
    font-weight: 400;
    font-size: 16px;
    line-height: 19.09px;
    color: #ffffffc9;
    font-family: "SFPRODISPLAYREGULAR",sans-serif;
}
h5 {
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
color: white;
    font-family: "roboto";
}
h6 {
    font-weight: 700;
    font-size: 18px;
    line-height: 18px;
    color: #ffffffde;
    font-family: "eurostile", sans-serif;
}
p {
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #ffffffde;
}
.nftprojects {
    color: black;
    padding: 10px 20px;
    border-radius: 2px;
    font-weight: 700;
    line-height: 16px;
    letter-spacing: 1.5%;
    font-family: "SFPRODISPLAYREGULAR",sans-serif;
    background-color: #3fffff;
    width: fit-content;
    font-size: 14px;
}

.power span {
    
    font-weight: 700;
    font-size: 18px;
    line-height: 18px;
    color: #3fffff;

    color: transparent;
    -webkit-text-stroke: 0.5px #eb0936;
}

--select-bg {
    background: blue !important;
}


.rc-md-editor .editor-container .sec-md .input {
    background: black !important;
    color: white !important;
}
.rc-md-editor .editor-container .sec-html .html-wrap {
    background: black !important;
    color: white !important;
}

.rc-md-editor .rc-md-navigation {
    background: black !important;
    color: white;
}
.rc-md-navigation {
    background: black !important;
}

.rmel-iconfont {
    display: flex !important;
    color: rgb(215, 215, 216) !important;
}

.rmel-iconfont:hover {
    color: rgb(134, 134, 134) !important;
}
.rmel-icon-underline {
    color: gray !important;
    pointer-events: none !important;
}
.postCardBg {
    background-image: linear-gradient(
        to right top,
        #030e1f,
        #0c0818,
        #0e0410,
        #0a0106,
        #000000
    );
}
.notFoundBg {
    width: 100vw;
    height: 100vh;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    background-color: black;
}

.borderNav {
    border-top: 0.5px solid;

    border-image-source: radial-gradient(
        15749.33% 5042917.33% at 50% 0.76%,
        #ffffff 0%,
        #151515 100%
    );
}

.blackFadeImage {
    background: transparent;
    background-position: center;
    background-repeat: no-repeat;
    mask-image: linear-gradient(
        to left,
        rgba(0, 0, 0, 0.096),
        rgba(0, 0, 0, 1)
    );
    background-size: cover;
}
.blackFadeImageBottom {
    background: transparent;
    background-position: center;
    background-repeat: no-repeat;
    mask-image: linear-gradient(to top, rgba(0, 0, 0, 0.096), rgba(0, 0, 0, 1));
    background-size: cover;
}

.notched-border::after {
    content: "";
    position: absolute;
    margin: -60px;
    width: 40px;
    border-right: 1px solid white;
    height: 40px;
    transform: rotate(45deg);
    background-color: #0f1012; /* to see where it is */
}

.notched-borderimg::after {
    content: "";
    position: absolute;
    margin-top: 200px;
    left: -20px;
    top: -220px;
    width: 40px;
    border-right: 1px solid white;
    height: 40px;
    transform: rotate(45deg);
    background-color: #0f1012; /* to see where it is */
}

.notched-border2::after {
    content: "";
    position: absolute;
    margin-top: 200px;
    right: -20px;
    bottom: -20px;
    width: 40px;
    border-left: 1px solid white;
    height: 40px;
    transform: rotate(45deg);
    background-color: #0f1012; /* to see where it is */
}

.bgBlue {
    background-image: url("../src/Assets/favicon/bgblue.png");
    background-position: center;
    background-repeat: no-repeat;

    background-size: cover;
}

.footerBg {
    background: linear-gradient(
        319.82deg,
        #3fffff 2.04%,
        #9462ff 22.25%,
        rgba(255, 148, 114, 0.4) 40.61%,
        #0f1012 61.76%
    );
}

.cryptoCardBorder {
    border: 0.5px solid rgba(255, 255, 255, 0.137);
    width: full;
}

@media screen and (max-width: 640px) {
    .homespan span {
        font-size: 16px; /* Adjust the font size for smaller screens as needed */
    }
    h1 {
        font-size: 14px;
    }
    .notched-border::after {
        content: "";
        position: absolute;
        margin: -20px;
        left: 0px;
        top: -120px;
        top: 0;
        width: 40px;
        border-right: 1px solid white;
        height: 40px;
        transform: rotate(45deg);
        background-color: #0f1012; /* to see where it is */
    }
}


@media screen and (max-width: 768px) {


    .notched-border::after {
        content: "";
        position: absolute;
        margin: -20px;
        left: 0px;
        top: -120px;
        top: 0;
        width: 40px;
        border-right: 1px solid white;
        height: 40px;
        transform: rotate(45deg);
        background-color: #0f1012; /* to see where it is */
    }
}